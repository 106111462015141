export const formatDate = (date: Date, year: boolean = false): string => {

    let resultParts = [date.getDate().toString().padStart(2, '0'), (date.getMonth() + 1).toString().padStart(2, '0')]
    if (year) {
        resultParts.push(date.getFullYear().toString().padStart(2, '0'))
    }

    return resultParts.join('/') + ' ' +
        date.getHours().toString().padStart(2, '0') + ':' + date.getMinutes().toString().padStart(2, '0')
}

export const formatTimestamp = (time: number, year: boolean = false): string => {
    return formatDate(new Date(time), year)
}