import {skeletonPost} from './skeleton/skeletonPost';
import {BookEventPayload, BookPlayerPayload, LoginPayload} from '../@types/actions';
import {skeletonGet} from "./skeleton/skeletonGet";

const UserEndpoint = 'user';

export const LoginCall = (payload: LoginPayload): Promise<any> => {
    return skeletonPost(payload, 'login', UserEndpoint)
};

export const LogoutCall = (): any => {
    return skeletonPost(null, 'logout', UserEndpoint)
};

export const GetUserInfoCall = (): Promise<any> => {
    return skeletonGet('session', UserEndpoint);
};

export const GetGroupBooksCall = (): Promise<any> => {
    return skeletonGet('books', 'group');
};

export const GetBookmakersCall = (): Promise<any> => {
    return skeletonGet('bookmakers', 'user');
};

export const GetInitialEventsCall = (): Promise<any> => {
    return skeletonGet('monitor', 'user');
};

export const GetInitialPlayerCall = (): Promise<any> => {
    return skeletonGet('player', 'user');
};

export const BookEventCall = (payload: BookEventPayload): Promise<any> => {
    return skeletonPost(payload, 'book/event', 'user');
};

export const BookPlayerCall = (payload: BookPlayerPayload): Promise<any> => {
    return skeletonPost(payload, 'book/player', 'user');
};