import {MARGIN_LIMIT} from "./CommonConstants";

const PREFIX = 'MONITOR'

enum MonitorConstants {
    MONITOR_INITIALIZE,
    MONITOR_INITIALIZE_SUCCESS,
    MONITOR_INITIALIZE_ERROR,
    STORE_MONITOR_INITIALIZE,
    STORE_MONITOR_INITIALIZE_ERROR,
    GET_MONITOR_SETTINGS,
    GET_MONITOR_SETTINGS_SUCCESS,
    DELETE_MONITOR_SETTINGS,
    DELETE_MONITOR_SETTINGS_SUCCESS,
    GET_MONITOR_EVENTS,
    GET_MONITOR_EVENTS_ERROR,
    GET_MONITOR_EVENTS_SUCCESS,
    NEW_MONITOR_EVENT,
    EDIT_MONITOR_EVENT,
    CLEAR_MONITOR_EVENT,
    NEW_BOOKED_EVENT,
    BOOK_EVENT,
    BOOK_EVENT_SUCCESS,
    BOOK_EVENT_ERROR,
    SEARCH_EVENT,
    SEARCH_EVENT_SUCCESS,
    GET_EVENT,
    GET_EVENT_SUCCESS,
    GET_EVENT_ERROR,
    DELETE_ALERT,
    DELETE_ALERT_SUCCESS,
    HIDE_ALERT,
    HIDE_ALERT_SUCCESS,
    NEW_HIDDEN_ALERT,
    GET_MONITOR_ODDS_EVENTS_SUCCESS,
    GET_MONITOR_ODDS_EVENTS,
    GET_EVENTS,
    GET_EVENTS_SUCCESS,
}

const SETTINGS = 'SETTINGS'

enum SettingConstants {
    SET_ORDER,
    SET_SPORT_FILTER,
    SET_SETTINGS,
}

export enum OrderConstants {
    NONE,
    DATE,
    MARKET,
    COMPARISON,
    BOOK
}

export const STORE_MONITOR_INITIALIZE = PREFIX + '_' + MonitorConstants.STORE_MONITOR_INITIALIZE;
export const STORE_MONITOR_INITIALIZE_ERROR = PREFIX + '_' + MonitorConstants.STORE_MONITOR_INITIALIZE_ERROR;
export const MONITOR_INITIALIZE = PREFIX + '_' + MonitorConstants.MONITOR_INITIALIZE;
export const MONITOR_INITIALIZE_SUCCESS = PREFIX + '_' + MonitorConstants.MONITOR_INITIALIZE_SUCCESS;
export const MONITOR_INITIALIZE_ERROR = PREFIX + '_' + MonitorConstants.MONITOR_INITIALIZE_ERROR;

export const GET_MONITOR_SETTINGS = PREFIX + '_' + MonitorConstants.GET_MONITOR_SETTINGS;
export const GET_MONITOR_SETTINGS_SUCCESS = PREFIX + '_' + MonitorConstants.GET_MONITOR_SETTINGS_SUCCESS;
export const DELETE_MONITOR_SETTINGS = PREFIX + '_' + MonitorConstants.DELETE_MONITOR_SETTINGS;
export const DELETE_MONITOR_SETTINGS_SUCCESS = PREFIX + '_' + MonitorConstants.DELETE_MONITOR_SETTINGS_SUCCESS;

export const GET_MONITOR_EVENTS = PREFIX + '_' + MonitorConstants.GET_MONITOR_EVENTS;
export const GET_MONITOR_EVENTS_ERROR = PREFIX + '_' + MonitorConstants.GET_MONITOR_EVENTS_ERROR;
export const GET_MONITOR_EVENTS_SUCCESS = PREFIX + '_' + MonitorConstants.GET_MONITOR_EVENTS_SUCCESS;
export const NEW_MONITOR_EVENT = PREFIX + '_' + MonitorConstants.NEW_MONITOR_EVENT;
export const EDIT_MONITOR_EVENT = PREFIX + '_' + MonitorConstants.EDIT_MONITOR_EVENT;
export const CLEAR_MONITOR_EVENT = PREFIX + '_' + MonitorConstants.CLEAR_MONITOR_EVENT;

export const GET_MONITOR_ODDS_EVENTS = PREFIX + '_' + MonitorConstants.GET_MONITOR_ODDS_EVENTS;
export const GET_MONITOR_ODDS_EVENTS_SUCCESS = PREFIX + '_' + MonitorConstants.GET_MONITOR_ODDS_EVENTS_SUCCESS;

export const SEARCH_EVENT = PREFIX + '_' + MonitorConstants.SEARCH_EVENT
export const SEARCH_EVENT_SUCCESS = PREFIX + '_' + MonitorConstants.SEARCH_EVENT_SUCCESS;
export const GET_EVENT = PREFIX + '_' + MonitorConstants.GET_EVENT;
export const GET_EVENT_SUCCESS = PREFIX + '_' + MonitorConstants.GET_EVENT_SUCCESS;
export const GET_EVENT_ERROR = PREFIX + '_' + MonitorConstants.GET_EVENT_ERROR;
export const GET_EVENTS = PREFIX + '_' + MonitorConstants.GET_EVENTS;
export const GET_EVENTS_SUCCESS = PREFIX + '_' + MonitorConstants.GET_EVENTS_SUCCESS;

export const BOOK_EVENT = PREFIX + '_' + MonitorConstants.BOOK_EVENT;
export const BOOK_EVENT_SUCCESS = PREFIX + '_' + MonitorConstants.BOOK_EVENT_SUCCESS;
export const BOOK_EVENT_ERROR = PREFIX + '_' + MonitorConstants.BOOK_EVENT_ERROR;

export const NEW_BOOKED_EVENT = PREFIX + '_' + MonitorConstants.NEW_BOOKED_EVENT;
export const DELETE_ALERT = PREFIX + '_' + MonitorConstants.DELETE_ALERT;
export const DELETE_ALERT_SUCCESS = PREFIX + '_' + MonitorConstants.DELETE_ALERT_SUCCESS;
export const HIDE_ALERT = PREFIX + '_' + MonitorConstants.HIDE_ALERT;
export const HIDE_ALERT_SUCCESS = PREFIX + '_' + MonitorConstants.HIDE_ALERT_SUCCESS;
export const NEW_HIDDEN_ALERT = PREFIX + '_' + MonitorConstants.NEW_HIDDEN_ALERT;

export const SET_SPORT_FILTER = SETTINGS + '_' + SettingConstants.SET_SPORT_FILTER;
export const SET_ORDER = SETTINGS + '_' + SettingConstants.SET_ORDER;
export const SET_SETTINGS = SETTINGS + '_' + SettingConstants.SET_SETTINGS;

export const MIN_LIMIT = -7;
export const MAX_LIMIT = MARGIN_LIMIT;