import {MarketData, MoneyLineData, OutcomeObject, PlayerMarketData} from "../@types/response";

const PREFIX = 'COMMON'

enum Constants {
    SET_BOOKMAKERS,
    GET_SPORTS,
    GET_SPORTS_SUCCESS,
    GET_SPORTS_ERROR,
    SET_ALIVE,

    GET_MARKET_CAP,
    GET_MARKET_CAP_SUCCESS,
    SET_MARKET_CAP,

    GET_EVENT_MONEY_LINE,
    GET_EVENT_MONEY_LINE_SUCCESS,
    SET_EVENT_MONEY_LINE,
}

export const SPORT_NAME: { [sportId: string]: string } = {
    '1': 'Soccer',
    '2': 'Basketball',
    '5': 'Tennis'
}

export enum Product {
    MONITOR = 'monitor',
    MARKET_PLUS = 'market_plus',
    PLAYER = 'player',
    DROPPING_ODDS = "dropping_odds"
}

export enum MarginFilter {
    HIDDEN = -1,
    DISABLED = 0,
    ENABLED = 1
}

export enum MarginMethod {
    NEW,
    ORIGINAL
}

export const HEARTBEAT_PRODUCTS = [Product.MONITOR, Product.MARKET_PLUS, Product.PLAYER]

export const SET_BOOKMAKERS = PREFIX + '_' + Constants.SET_BOOKMAKERS;

export const GET_SPORTS = PREFIX + '_' + Constants.GET_SPORTS;
export const GET_SPORTS_SUCCESS = PREFIX + '_' + Constants.GET_SPORTS_SUCCESS;
export const GET_SPORTS_ERROR = PREFIX + '_' + Constants.GET_SPORTS_ERROR;

export const SET_ALIVE = PREFIX + '_' + Constants.SET_ALIVE;

export const GET_MARKET_CAP = PREFIX + '_' + Constants.GET_MARKET_CAP;
export const GET_MARKET_CAP_SUCCESS = PREFIX + '_' + Constants.GET_MARKET_CAP_SUCCESS;
export const SET_MARKET_CAP = PREFIX + '_' + Constants.SET_MARKET_CAP;

export const GET_EVENT_MONEY_LINE = PREFIX + '_' + Constants.GET_EVENT_MONEY_LINE;
export const GET_EVENT_MONEY_LINE_SUCCESS = PREFIX + '_' + Constants.GET_EVENT_MONEY_LINE_SUCCESS;
export const SET_EVENT_MONEY_LINE = PREFIX + '_' + Constants.SET_EVENT_MONEY_LINE;


export const AVERAGE = 0;
export const AVERAGE_2 = -1;
export const BETFAIR = 2;
export const PINNACLE = 3;

export const COMPARISONS = [BETFAIR, PINNACLE]
export const COMPARISONS_PRIORITY = {[BETFAIR.toString()]: 0, [PINNACLE.toString()]: 1, [AVERAGE.toString()]: 2}

export const SOCCER = 1;
export const TENNIS = 2;
export const BASKET = 5;

export const MARGIN_LIMIT = 10;

export const MARKETS: MarketData = {
    "1": {
        id: 1,
        name: "1X2",
        outcomes: {"1": {name: "1"}, "2": {name: "X"}, "3": {name: "2"}},
        hasSbvs: false,
        sport: 1
    },
    "2": {
        id: 2,
        name: "O/U",
        outcomes: {"2": {name: "Over"}, "3": {name: "Under"}},
        hasSbvs: false,
        sport: 1
    },
    "3": {
        id: 3,
        name: "H/H",
        outcomes: {"1": {name: "1"}, "2": {name: "2"}},
        hasSbvs: false,
        sport: 2
    },
    "4": {
        id: 4,
        name: "H/H",
        outcomes: {"1": {name: "1"}, "2": {name: "2"}},
        hasSbvs: false,
        sport: 5
    },
    "9": {
        id: 9,
        name: "BTTS",
        outcomes: {"2": {name: "Yes"}, "3": {name: "No"}},
        hasSbvs: false,
        sport: 1
    },

    "11": {
        id: 11,
        name: "O/U 1.5",
        outcomes: {"2": {name: "Over"}, "3": {name: "Under"}},
        hasSbvs: false,
        sport: 1
    },
    "12": {
        id: 12,
        name: "O/U 3.5",
        outcomes: {"2": {name: "Over"}, "3": {name: "Under"}},
        hasSbvs: false,
        sport: 1
    },
    "13": {
        id: 13,
        name: "O/U 4.5",
        outcomes: {"2": {name: "Over"}, "3": {name: "Under"}},
        hasSbvs: false,
        sport: 1
    },
    "14": {
        id: 14,
        name: "1X2 HT",
        outcomes: {"1": {name: "1"}, "2": {name: "X"}, "3": {name: "2"}},
        hasSbvs: false,
        sport: 1
    },
    "15": {
        id: 15,
        name: "O/U 1.5 HT",
        outcomes: {"2": {name: "Over"}, "3": {name: "Under"}},
        hasSbvs: false,
        sport: 1
    }
}

export const PLAYER_MARKETS: PlayerMarketData = {
    "Goalscorers": {id: "Goalscorers", name: "Goalscorers", outcomes: {"Any": {name: "Anytime"}, "1st": {name: "First"}, "2o+": {name: "2orMore"}, "3o+": {name: "3orMore"}}},
    "Halves": {id: "Halves", name: "Halves", outcomes: {"AnyHT": {name: "AnyHT"}, "BHlv": {name: "BothHalves"}}},
    "Assist/Card": {id: "Assist/Card", name: "Assist/Card", outcomes: {"Asst": {name: "Assist"}, "Cd": {name: "Card"}, "1stCd": {name: "FirstCard"}}},
    "ShotsOnTarget": {id: "ShotsOnTarget", name: "Shots On Target", outcomes: {"1SOT": {name: "1 TP"}, "2SOT": {name: "2 TP"}, "3SOT": {name: "3 TP"}, "4SOT": {name: "4 TP"}, "5SOT": {name: "5 TP"}}},
    "TotalShots": {id: "TotalShots", name: "Total Shots", outcomes: {"1TS": {name: "1 TT"}, "2TS": {name: "2 TT"}, "3TS": {name: "3 TT"}, "4TS": {name: "4 TT"}, "5TS": {name: "5 TT"}, "6TS": {name: "6 TT"}, "7TS": {name: "7 TT"}}},
    "Points": {id: "Points", name: "Points", outcomes: {"O": {name: "Over"}, "U": {name: "Under"}}, sbv: true},
    "Assists": {id: "Assists", name: "Assists", outcomes: {"2": {name: "Over"}, "3": {name: "Under"}}, sbv: true},
    "Rebounds": {id: "Rebounds", name: "Rebounds", outcomes: {"2": {name: "Over"}, "3": {name: "Under"}}, sbv: true},
    "3Points": {id: "3Points", name: "3Points", outcomes: {"2": {name: "Over"}, "3": {name: "Under"}}, sbv: true},
    "Steals": {id: "Steals", name: "Steals", outcomes: {"2": {name: "Over"}, "3": {name: "Under"}}, sbv: true},
    "Turnovers": {id: "Turnovers", name: "Turnovers", outcomes: {"2": {name: "Over"}, "3": {name: "Under"}}, sbv: true},
    "Blocks": {id: "Blocks", name: "Blocks", outcomes: {"2": {name: "Over"}, "3": {name: "Under"}}, sbv: true}
}

export const PLAYER_DETAILS_MARKETS: { [sportId: string]: string[] } = {
    '1': ["Goalscorers", "Halves", "Assist/Card", "ShotsOnTarget", "TotalShots"],
    '2': ["Points", "Assists", "Rebounds", "3Points", "Steals", "Turnovers", "Blocks"]
}

export const getOutcomesName = (marketId: string, signId: string) => {
    const market: OutcomeObject = MARKETS[marketId].outcomes;
    if (!market.length || !market[signId]) return '-'
    return market[signId].name
}

export const getMarketName = (marketId: string) => {
    const market = MARKETS[marketId] || PLAYER_MARKETS[marketId];
    return market?.name ?? "-"
};

export const getMoneyLine = (marketId: number, moneyLine: MoneyLineData): number | null => {
    switch (marketId) {
        case 1:
        case 3:
        case 4:
        case 9:
            return moneyLine.maxMoneyline
        case 2:
            return moneyLine.maxTotal
    }
    return null
}