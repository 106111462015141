import {SetOrderAction} from '../../../store/actions/monitor/SetOrderAction';
import {useDispatch, useSelector} from 'react-redux';
import {MonitorSettingsObject} from '../../../@types/response';
import {getSettings} from '../../../store/selectors/monitor/getSettings';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSortDown} from '@fortawesome/free-solid-svg-icons'
import {OrderConstants} from "../../../constants/MonitorConstants";
import {getMonitorsLegth} from "../../../store/selectors/monitor/getMonitorsLegth";


export default function MonitorTable({children}: { children?: any }) {

    const settings: MonitorSettingsObject = useSelector(getSettings);
    const showedAlerts: number = useSelector(getMonitorsLegth);
    const dispatch = useDispatch();

    return (
            <div>
                <div className='2xl:monitor-table-header-wrapper'>
                    <table className='text-monitor-header w-full '>
                        <thead>
                        <tr className='uppercase 2xl:text-monitorBase text-monitorSm tracking-[-0.11px] items-center leading-normal'>
                            <th className='2xl:pl-28 pl-16 py-1 text-left 2xl:w-auto'>Event/Competition</th>
                            <th className='py-1 text-center cursor-pointer relative 2xl:w-44 w-28 ' onClick={() => {
                                settings.order !== OrderConstants.DATE && dispatch(SetOrderAction(OrderConstants.DATE))
                            }}>Date {settings.order === OrderConstants.DATE &&
                                    <div className='absolute 2xl:right-12 right-8 top-0'>
                                        <FontAwesomeIcon icon={faSortDown}/>
                                    </div>
                            }
                            </th>
                            <th className='py-1 px-6 text-left cursor-pointer relative 2xl:w-24 w-20' onClick={() => {
                                settings.order !== OrderConstants.MARKET && dispatch(SetOrderAction(OrderConstants.MARKET))
                            }}>M {settings.order === OrderConstants.MARKET &&
                                    <div className='absolute 2xl:right-12 right-8 top-0'>
                                        <FontAwesomeIcon icon={faSortDown}/>
                                    </div>
                            }
                            </th>
                            <th className='py-1 px-3 text-center 2xl:w-24 w-16'>Market</th>
                            <th className='py-1 px-3 text-center cursor-pointer relative 2xl:w-40 w-28'
                                onClick={() => {
                                    settings.order !== OrderConstants.BOOK && dispatch(SetOrderAction(OrderConstants.BOOK))
                                }}>Book
                                {settings.order === OrderConstants.BOOK &&
                                        <div className='absolute 2xl:right-10 right-7 top-0'>
                                            <FontAwesomeIcon icon={faSortDown}/>
                                        </div>}
                            </th>
                            <th className='py-1 text-center 2xl:w-44 w-36 '>Odds</th>
                            <th className='py-1  text-center cursor-pointer relative 2xl:w-44 w-36 '
                                onClick={() => {
                                    settings.order !== OrderConstants.COMPARISON && dispatch(SetOrderAction(OrderConstants.COMPARISON))
                                }}>Comparison
                                {settings.order === OrderConstants.COMPARISON &&
                                        <div className='absolute right-2 top-0'>
                                            <FontAwesomeIcon icon={faSortDown}/>
                                        </div>}
                            </th>
                            <th className='2xl:w-32 w-28'>TOT</th>
                            <th className='2xl:w-48 w-32'>
                                <p className='2xl:text-base px-2 text-monitorSm text-black float-left bg-white'>{showedAlerts}</p>
                            </th>
                        </tr>
                        </thead>
                    </table>
                </div>
                <div style={{
                    background: "url('img/table_background.jpg')",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover"
                }}
                     className='2xl:monitor-table-wrapper border-2 border-monitor-header bg-table-monitor monitor-table-wrapper-1440 overflow-auto dropdown-hidden-scrollbar relative rounded-[40px] mb-4'>
                    <table className='text-monitor-header w-full'>
                        <tbody className='2xl:text-monitorBase text-monitorSm font-normal'>
                        {children}
                        </tbody>
                    </table>
                </div>
            </div>
    )
}
