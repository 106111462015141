import React, {useCallback, useContext, useEffect, useState} from 'react';
import {ModalsContext} from './ModalsContext';
import {MODALS_TYPES} from '../../../constants/ModalsConstants';
import {GetEventPlayersCall, MapPlayersCall} from "../../../apis/player";
import {PlayerBookmakersStoredObject, PlayerMarketData} from '../../../@types/response';
import {MapPlayerObject, MarketTable} from '../player/MarketTable';
import {useSelector} from "react-redux";
import {getPlayerMarkets} from "../../../store/selectors/getPlayerMarkets";
import {EventModalProps} from '../../../@types/inputs';
import {GetEventCall} from '../../../apis/event';
import {faSearch} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {PLAYER_DETAILS_MARKETS, PLAYER_MARKETS} from "../../../constants/CommonConstants";
import {getMainSbv} from "../../../store/utils/playerUtils";

const MarketPlayerModal: React.FC = () => {
    const {modalsData, openedModals, setOpenedModalsCallback} = useContext(ModalsContext);
    const opening = openedModals.indexOf(MODALS_TYPES.PLAYER_MARKET_MODAL) !== -1
    const onCancelClickCb = useCallback(() => {
        setOpenedModalsCallback(MODALS_TYPES.PLAYER_MARKET_MODAL, false);
        resetModal();
    }, [setOpenedModalsCallback]);

    const playerMarkets: PlayerMarketData = useSelector(getPlayerMarkets);

    const event: EventModalProps = (modalsData[MODALS_TYPES.PLAYER_MARKET_MODAL] || {}).event;
    const selectedMarketId: string = (modalsData[MODALS_TYPES.PLAYER_MARKET_MODAL] || {}).marketId;
    const selectedSignId: string = (modalsData[MODALS_TYPES.PLAYER_MARKET_MODAL] || {}).signId;

    const [mapMode, setMapMode] = useState<boolean>(false);
    const [searchValue, setSearchValue] = useState<string>("");
    const [books, setBooks] = useState<PlayerBookmakersStoredObject>({});
    const [markets, setMarkets] = useState<any[]>([]);
    const [marketId, setMarketId] = useState<string>(selectedMarketId);
    const [signId, setSignId] = useState<string>(selectedSignId);
    const [eventDetail, setEventDetail] = useState<any>('');
    const [mapClick, setMapClick] = useState<boolean>(false);
    const [sbvs, setSbvs] = useState<{ [playerId: string]: { [bookmaker: string]: string } }>({})

    const escFunction = useCallback((e: KeyboardEvent) => {
        if (e.key === 'Escape') {
            setOpenedModalsCallback(MODALS_TYPES.PLAYER_MARKET_MODAL, false);
            resetModal();
        }
    }, [setOpenedModalsCallback]);

    useEffect(() => {
        setMarketId(selectedMarketId)
        setSignId(selectedSignId)
    }, [selectedMarketId, selectedSignId])

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, [escFunction]);

    useEffect(() => {
        if (!opening || !event?.id) return;
        GetEventCall({eventId: event.id}).then((result) => setEventDetail(result))
    }, [opening, event])

    useEffect(() => {
        if (!opening || !event?.id) return;
    }, [opening, event])

    useEffect(() => {
        if (eventDetail === '' || !eventDetail) return;
        let allowedMarkets = PLAYER_DETAILS_MARKETS[eventDetail.sportId] || PLAYER_DETAILS_MARKETS['1']
        if (marketId === '') {
            setMarketId(eventDetail.sportId === 2 ? 'Points' : 'Goalscorers')
            setSignId(eventDetail.sportId === 2 ? "O" : "Any")
        }
        GetEventPlayersCall(event?.id).then((result) => {
            setMarkets(Object.values(playerMarkets).filter(m => allowedMarkets.includes(m.id)).flatMap(market =>
                    Object.entries(market.outcomes).map(([outcomeId, outcome]) => ({
                        market: {id: market.id, name: market.name},
                        outcome: {id: outcomeId, name: outcome.name}
                    }))
            ))
            setSbvs(getMainSbv(result, marketId === '' ? eventDetail.sportId === 2 ? 'Points' : 'Goalscorers' : marketId))
            setBooks(result)
        }, () => {
            setMarkets(Object.values(playerMarkets).filter(m => allowedMarkets.includes(m.id)).flatMap(market =>
                    Object.entries(market.outcomes).map(([outcomeId, outcome]) => ({
                        market: {id: market.id, name: market.name},
                        outcome: {id: outcomeId, name: outcome.name}
                    }))
            ))
            setSbvs({})
            setBooks({})
        })
    }, [playerMarkets, eventDetail])

    const mapPlayers = (playersToMap: MapPlayerObject) => {
        MapPlayersCall(event?.id, Object.values(playersToMap)).then((result) => {
            setBooks(result)
        }, () => {
            setBooks(books)
        })
    }

    const resetButton = () => {
        setMapClick(false)
    }

    const resetModal = () => {
        setMapMode(false)
        setMarketId(event?.sportId === 2 ? 'Points' : 'Goalscorers')
        setSignId(event?.sportId === 2 ? '2' : 'Any')
        setSearchValue('')
        setBooks({})
        setEventDetail('')
        setMarketId('')
        setSignId('')
    }

    const startDt = new Date(eventDetail?.date)
    return (
            (openedModals.indexOf(MODALS_TYPES.PLAYER_MARKET_MODAL) > -1 && (
                    <div className='fixed z-10 inset-0 overflow-y-auto'>
                        <div className='flex items-center justify-center min-h-screen text-center relative'>
                            <div className='fixed inset-0 bg-modal-layer transition-opacity'/>
                            <div style={{maxWidth: '1536px', minWidth: '640px'}}
                                 className={`bg-eventDetail-primary rounded-lg text-left shadow-xl transform transition-all border-solid border-eventDetail-secondary border-2`}>
                                <button
                                        type='button'
                                        className='hover:bg-blue-600 hover:text-black hover:border-black absolute z-20 bg-eventDetail-primary -right-4 -top-4 text-eventDetail-event border border-eventDetail-event rounded-full h-8 w-8'
                                        onClick={onCancelClickCb}
                                >
                                    x
                                </button>
                                <div className='text-white px-4 py-2'>
                                    <div className='mt-2'>
                                        <div className='flex border-b pb-2'>
                                            <div style={{
                                                background: "url('img/logo_icon.svg')",
                                                backgroundRepeat: 'no-repeat',
                                                backgroundPosition: 'center'
                                            }}
                                                 className='w-[60px] h-[60px] mb-2.5'></div>
                                            <div className='flex flex-col justify-end mb-2.5 ml-2.5'>
                                                <h2 className='mb-3 w-100'><span className='absolute right-4 top-1'>
                                                <button className={"2xl:text-monitorBase text-monitorSm py-1 font-normal block w-full whitespace-nowrap"}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            mapMode ? setMapMode(false) : setMapMode(true)
                                                        }}
                                                >
                                                    <label className="flex items-center cursor-pointer">
                                                        <div className="relative">
                                                            <div className="2xl:w-10 w-7 2xl:h-5 h-4 border-2 border-dropDown-buttonBorder rounded-full shadow-inner"/> 
                                                            <div className="dot absolute 2xl:w-3 2xl:h-3 w-2 h-2 bg-dropDown-buttonBorder rounded-full shadow left-1 bottom-1 transition"
                                                                 style={mapMode ? {
                                                                     transform: "translateX(160%) ",
                                                                     backgroundColor: "#BABABA"
                                                                 } : {backgroundColor: "#222222"}}
                                                            />
                                                        </div>
                                                        <div className="ml-3 text-white font-bold">MAP MODE</div>
                                                    </label>
                                                </button>
                                                    </span></h2>
                                                <h2>{event?.name?.substring(0, 40) || ''}<span
                                                        className='absolute right-4'>ID: {event.id}</span></h2>
                                                <h2 className='text-eventDetail-event font-medium'>{(eventDetail?.category || "").substring(0, 15)} - {eventDetail?.tournament} | {[startDt.getDate().toString().padStart(2, '0'), (startDt.getMonth() + 1).toString().padStart(2, '0'), (startDt.getFullYear()).toString().padStart(2, '0')].join('/')} {`${startDt.getHours().toString().padStart(2, '0')}:${startDt.getMinutes().toString().padStart(2, '0')}`}
                                                    {mapMode && (<button onClick={() => {
                                                        setMapClick(true)
                                                    }}
                                                                         className='absolute hover:text-eventDetail-event font-bold right-4 text-white rounded px-2 bg-[#272C34]'>MAP</button>)}
                                                </h2>
                                            </div>
                                        </div>
                                        <div className='flex pb-6 mb-3 mt-2'>
                                            <h2>
                                                <div className='flex absolute'>
                                                    <input
                                                            className='2xl:text-monitorBase text-monitorSm text-white bg-[#272C34] focus:outline-none rounded-xl p-1'
                                                            placeholder="filter players"
                                                            value={searchValue}
                                                            onChange={(e) => {
                                                                setSearchValue(e.target.value)
                                                            }}
                                                    />
                                                    <FontAwesomeIcon className='relative right-6 top-1 text-[#545659]'
                                                                     icon={faSearch}/>
                                                </div>
                                                <select
                                                        className='text-black absolute right-4 mt-1'
                                                        onChange={(e) => {
                                                            let market = e.target.value.toString().split(' - ')[0]
                                                            let sign = e.target.value.toString().split(' - ')[1]
                                                            setMarketId(market)
                                                            setSignId(sign)
                                                            setSbvs(getMainSbv(books, market))
                                                        }}
                                                        value={marketId + ' - ' + signId}
                                                >
                                                    {markets.map((p) => <option
                                                            key={p.market.id + ' - ' + p.outcome.id}
                                                            value={p.market.id + ' - ' + p.outcome.id}
                                                    >{p.market.name + ' - ' + p.outcome.name}</option>)}
                                                </select>
                                            </h2>
                                        </div>
                                        <div
                                                className='w-full overflow-x-scroll overflow-y-visible h-[520px] dropdown-hidden-scrollbar'
                                        >{marketId !== '' &&
                                                <MarketTable
                                                        marketId={marketId}
                                                        signId={signId}
                                                        odds={books}
                                                        mapMode={mapMode}
                                                        searchValue={searchValue}
                                                        mapClick={mapClick}
                                                        mapPlayers={mapPlayers}
                                                        mapClicked={resetButton}
                                                        average={(marketId && PLAYER_MARKETS[marketId]) ? (PLAYER_MARKETS[marketId].sbv === undefined) : true}
                                                        sbv={sbvs}
                                                />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            )) ||
            null
    )
};
export default MarketPlayerModal;
