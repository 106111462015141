const initialState = {};

const hotMatchReducer = (state = initialState, action: any) => {
    switch (action.type) {

        default:
            return state;
    }
};

export default hotMatchReducer;
