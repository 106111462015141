import React, {useCallback, useContext, useEffect, useState} from 'react';
import {ModalsContext} from './ModalsContext';
import {MODALS_TYPES} from '../../../constants/ModalsConstants';
import {OddsTable} from "../common/OddsTable";
import {GetEventCall} from "../../../apis/event";
import {EventModalProps} from "../../../@types/inputs";

const EventDetailsModal: React.FC = () => {
    const {modalsData, openedModals, setOpenedModalsCallback} = useContext(ModalsContext);
    const opening = openedModals.indexOf(MODALS_TYPES.EVENT_DETAILS_MODAL) !== -1
    const onCancelClickCb = useCallback(() => {
        setOpenedModalsCallback(MODALS_TYPES.EVENT_DETAILS_MODAL, false);
    }, [setOpenedModalsCallback]);

    const event: EventModalProps = (modalsData[MODALS_TYPES.EVENT_DETAILS_MODAL] || {}).event;
    const [books, setBooks] = useState<any>();

    const escFunction = useCallback((event: KeyboardEvent) => {
        if (event.key === 'Escape') {
            setOpenedModalsCallback(MODALS_TYPES.EVENT_DETAILS_MODAL, false);
        }
    }, []);

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);

        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, [escFunction]);

    useEffect(() => {
        if (!opening || !event?.id) return;
        GetEventCall({eventId: event.id}).then((result) => setBooks(result))
    }, [opening])
    const startDt = new Date(books?.date)
    return (
            (openedModals.indexOf(MODALS_TYPES.EVENT_DETAILS_MODAL) > -1 && (
                    <div className='fixed z-10 inset-0 overflow-y-auto'>
                        <div className='flex items-center justify-center min-h-screen text-center relative'>
                            <div className='fixed inset-0 bg-modal-layer transition-opacity'/>
                            <div className='bg-eventDetail-primary rounded-lg text-left shadow-xl transform transition-all
                        sm:max-w-screen-sm sm:w-full border-solid border-eventDetail-secondary border-2'>
                                <button
                                        type='button'
                                        className='hover:bg-blue-600 hover:text-black hover:border-black absolute z-20 bg-eventDetail-primary -right-4 -top-4 text-eventDetail-event border border-eventDetail-event rounded-full h-8 w-8'
                                        onClick={onCancelClickCb}
                                >
                                    x
                                </button>
                                <div className='text-white px-4 py-2'>
                                    <div className='mt-2'>
                                        <div className='flex border-b'>
                                            <div style={{
                                                background: "url('/img/logo_icon.svg')",
                                                backgroundRepeat: 'no-repeat',
                                                backgroundPosition: 'center'
                                            }}
                                                 className='w-[60px] h-[60px] mb-2.5'></div>
                                            <div className='flex flex-col justify-end mb-2.5 ml-2.5'>
                                                <h2>{event.name.substring(0, 40)}<span
                                                        className='absolute right-4'>ID: {event.id}</span></h2>
                                                <h2 className='text-eventDetail-event font-medium'>{(books?.category || "").substring(0, 15)} - {books?.tournament} | {[startDt.getDate().toString().padStart(2, '0'), (startDt.getMonth() + 1).toString().padStart(2, '0'), (startDt.getFullYear()).toString().padStart(2, '0')].join('/')} {`${startDt.getHours().toString().padStart(2, '0')}:${startDt.getMinutes().toString().padStart(2, '0')}`}</h2>
                                            </div>
                                        </div>
                                        <div
                                                className='w-full overflow-x-scroll overflow-y-visible h-[520px] dropdown-hidden-scrollbar'>
                                            <OddsTable
                                                    sport={event.sportId}
                                                    odds={books?.bookmakers || {}}
                                                    event={event}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            )) ||
            null
    )
};
export default EventDetailsModal;
