import {
    AlertMonitorObject,
    ComparisonSettingsObject,
    EventBookmakerObject,
    EventMonitorStoredObject,
    EventsStoredObjects,
    FixtureObject,
    HiddenObject,
    MarketCapObject,
    MoneyLineData,
    MonitorSettingsObject,
    SportsFilter
} from "../../@types/response";
import {
    AVERAGE,
    BETFAIR,
    COMPARISONS,
    COMPARISONS_PRIORITY,
    MarginMethod,
    PINNACLE
} from "../../constants/CommonConstants";
import {OrderConstants} from "../../constants/MonitorConstants";
import {getAverageBookmaker, getBetfairChart, isVisible, processComparison} from "./events";

/**
 * Sort Events
 *
 * @param a
 * @param b
 * @param events
 * @param settings
 */
export const sortEvent = (a: AlertMonitorObject, b: AlertMonitorObject, events: EventsStoredObjects, settings: MonitorSettingsObject): number => {
    switch (settings.order) {
        case OrderConstants.DATE:
            if (events[a.id].date !== events[b.id].date) {
                return events[a.id].date - events[b.id].date
            } else {
                return a.margin - b.margin
            }
        case OrderConstants.MARKET:
            if (a.margin !== b.margin) {
                return a.margin - b.margin
            } else {
                return events[a.id].name > events[b.id].name ? 1 : -1
            }
        case OrderConstants.COMPARISON:
            if (a.comparisonType === b.comparisonType) {
                return a.margin - b.margin
            }
            return COMPARISONS_PRIORITY[a.comparisonType.toString()] - COMPARISONS_PRIORITY[b.comparisonType.toString()]
        case OrderConstants.BOOK:
            if (a.bookmakerId === b.bookmakerId) {
                return a.margin - b.margin
            } else {
                return a.bookmakerId > b.bookmakerId ? 1 : -1;
            }
        case OrderConstants.NONE:
        default:
            return 0
    }
}

/**
 * Generate Betfair URL
 *
 * @param alert
 * @param event
 *
 * @return string | null
 */
export const getBetfairChartUrl = (alert: AlertMonitorObject, event: EventMonitorStoredObject): string | null => {
    if (!event.marketCap) return null
    try {
        const bMarketId = event.marketCap[alert.marketId]['bMarketId'].toString();
        const bSelectionId = event.marketCap[alert.marketId][alert.signId.toString()].toString();
        return getBetfairChart(bMarketId, bSelectionId)
    } catch (e) {
        return null
    }
}

/**
 * Apply Filters
 *
 * @param filter
 * @param sports
 * @param settings
 */
export const calculateFilter = (filter: SportsFilter, sports: FixtureObject, settings: SportsFilter): SportsFilter => {
    const result: SportsFilter = {...settings}
    Object.keys(filter).forEach(sportId => {
        if (result[sportId] === true) {
            result[sportId] = Object.fromEntries(Object.keys(sports[sportId]).map(k => [k, true]))
        }
        if (filter[sportId] === true || Object.keys(filter[sportId]).length === 0) {
            result[sportId] = filter[sportId]
            return
        } else if (!(sportId in result)) {
            result[sportId] = {}
        }
        Object.keys(filter[sportId]).forEach(categoryId => {
            // @ts-ignore
            result[sportId][categoryId] = filter[sportId][categoryId]
        })
    })
    return result
}


/**
 * eventsListener
 *
 * @returns AlertMonitorObject
 * @param event
 * @param settings
 * @param booked
 * @param hides
 * @param marketsCap
 * @param moneyLine
 */
export function processEvent(event: EventMonitorStoredObject, settings: MonitorSettingsObject, booked: string[], hides: HiddenObject, marketsCap: MarketCapObject, moneyLine: MoneyLineData): AlertMonitorObject[] {
    let alerts: any = [];
    try {

        if (!('bookmakers' in event) || !settings || !isVisible(event, settings.sports)) {
            return []
        }

        const expire = new Date()
        expire.setHours(0)
        expire.setMinutes(0)
        expire.setSeconds(0)
        expire.setDate(expire.getDate() + settings.date)

        const startdt = new Date(event.date)
        if ((startdt < new Date()) || (settings.date > 0 && startdt >= expire)) {
            return []
        }

        const bookmakers: any = event.bookmakers
        const bookmakerToCompare: any = Object.keys(event.bookmakers || {})
            .filter((bookmakerId) => settings.bookmakers.includes(parseInt(bookmakerId)) && !COMPARISONS.includes(parseInt(bookmakerId)))
            .map(bookmakerId => Object.assign({}, bookmakers[bookmakerId], {id: bookmakerId}))

        //Start BETFAIR calculation
        if (settings.comparisons[BETFAIR.toString()]?.status && Object.keys(bookmakers).includes(BETFAIR.toString())) {
            const bComparison = Object.assign({}, bookmakers[BETFAIR.toString()], {comparisonType: BETFAIR})
            alerts = alerts.concat(
                processComparison(event._id, bComparison, bookmakerToCompare, settings, booked, hides, marketsCap, null)
            )
        }

        //Start PINNACLE calculation
        if (settings.comparisons[PINNACLE.toString()]?.status && Object.keys(bookmakers).includes(PINNACLE.toString())) {
            const bComparison = Object.assign({}, bookmakers[PINNACLE.toString()], {comparisonType: PINNACLE})
            alerts = alerts.concat(
                processComparison(event._id, bComparison, bookmakerToCompare, settings, booked, hides, null, moneyLine)
            )
        }

        //Start AVERAGE calculation
        const averageBookmakers = settings.comparisons[AVERAGE.toString()]?.bookmakers
        const activeBookmakers: EventBookmakerObject[] = Object.keys(event.bookmakers || {})
            .filter((bookmakerId) => !COMPARISONS.includes(parseInt(bookmakerId)))
            .filter((bookmakerId) => averageBookmakers?.length ? averageBookmakers.includes(parseInt(bookmakerId)) : false)
            .map((bookmakerId) => event.bookmakers![bookmakerId])
        if (settings.comparisons[AVERAGE.toString()]?.status && activeBookmakers.length > 1) {
            const bComparison = getAverageBookmaker(event._id, activeBookmakers)
            alerts = alerts.concat(
                processComparison(event._id, bComparison, bookmakerToCompare, settings, booked, hides, null, null)
            )
        }
    } catch (error) {
        console.error('Error on process event', error, event)
    }
    return alerts
}

/**
 * newMonitorSetting
 *
 * @param settings
 */
export function buildSettings(settings: MonitorSettingsObject): MonitorSettingsObject {
    Object.keys(settings.comparisons)
        .forEach((comparisonId) => {
            settings.comparisons[comparisonId] = buildComparisonSettings(settings.comparisons[comparisonId], comparisonId)
        })
    return settings
}

/**
 * newMonitorSetting
 *
 * @param settings
 * @param comparisonId
 */
function buildComparisonSettings(settings: ComparisonSettingsObject, comparisonId: string): ComparisonSettingsObject {
    settings.margin = comparisonId === BETFAIR.toString() ? null : (settings.margin ?? 100 - (settings.bound || 0))
    settings.threshold = settings.threshold ?? (settings.limit || 0)
    settings.amountMarket = comparisonId === AVERAGE.toString() ? 0 : (settings.amountMarket ?? 1000)
    settings.amountOutcome = comparisonId === PINNACLE.toString() || comparisonId === AVERAGE.toString() ? 0 : (settings.amountOutcome ?? 100)
    settings.method = settings.method ?? MarginMethod.ORIGINAL
    settings.bookmakers = comparisonId === AVERAGE.toString() && settings.bookmakers ? settings.bookmakers : []
    return settings
}
