import {
    AlertPlayerObject,
    EventPlayerStoredObjects,
    EventsStoredObjects,
    EventStoredObject,
    HiddenObject,
    PlayerBookmakersStoredObject,
    PlayerBookmakerStoredObject,
    PlayerMarketStoredObject,
    PlayerSettingsObject,
    PlayerStoredObject,
    ProcessPlayerBookmakerStoredObject,
    SignStoredObject,
} from "../../@types/response";
import {
    AVERAGE,
    AVERAGE_2,
    BETFAIR,
    COMPARISONS,
    COMPARISONS_PRIORITY,
    MarginMethod,
    PLAYER_MARKETS,
} from "../../constants/CommonConstants";
import {OrderConstants} from "../../constants/PlayerConstants";
import {getBetfairChart, isVisible} from "./events";

/**
 * Sort Events
 *
 * @param a
 * @param b
 * @param events
 * @param settings
 */
export const sortEvent = (
    a: AlertPlayerObject,
    b: AlertPlayerObject,
    events: EventsStoredObjects,
    settings: PlayerSettingsObject
): number => {
    switch (settings.order) {
        case OrderConstants.DATE:
            if (events[a.eventId].date !== events[b.eventId].date) {
                return events[a.eventId].date - events[b.eventId].date;
            } else if (a.margin !== b.margin) {
                return a.margin - b.margin;
            } else if (a.name !== b.name) {
                return a.name > b.name ? 1 : -1;
            } else if (a.marketId !== b.marketId) {
                return a.marketId > b.marketId ? 1 : -1;
            } else {
                return a.bookmakerId > b.bookmakerId ? 1 : -1;
            }
        case OrderConstants.MARKET:
            if (a.margin !== b.margin) {
                return a.margin - b.margin;
            } else if (a.name !== b.name) {
                return a.name > b.name ? 1 : -1;
            } else if (a.marketId !== b.marketId) {
                return a.marketId > b.marketId ? 1 : -1;
            } else {
                return a.bookmakerId > b.bookmakerId ? 1 : -1;
            }
        case OrderConstants.MARKET2:
            if (a.margin !== b.margin) {
                return b.margin - a.margin;
            } else if (a.name !== b.name) {
                return a.name < b.name ? 1 : -1;
            } else if (a.marketId !== b.marketId) {
                return a.marketId < b.marketId ? 1 : -1;
            } else {
                return a.bookmakerId < b.bookmakerId ? 1 : -1;
            }
        case OrderConstants.COMPARISON:
            if (a.comparisonType === b.comparisonType) {
                if (a.margin !== b.margin) {
                    return settings.comparisons[AVERAGE_2].status ? b.margin - a.margin : a.margin - b.margin;
                } else if (a.name !== b.name) {
                    return a.name > b.name ? 1 : -1;
                } else if (a.marketId !== b.marketId) {
                    return a.marketId > b.marketId ? 1 : -1;
                } else {
                    return a.bookmakerId > b.bookmakerId ? 1 : -1;
                }
            }
            return (
                COMPARISONS_PRIORITY[a.comparisonType.toString()] - COMPARISONS_PRIORITY[b.comparisonType.toString()]
            );
        case OrderConstants.BOOK:
            if (a.bookmakerId === b.bookmakerId) {
                if (a.margin !== b.margin) {
                    return a.margin - b.margin;
                } else if (a.name !== b.name) {
                    return a.name > b.name ? 1 : -1;
                } else {
                    return a.marketId > b.marketId ? 1 : -1;
                }
            } else {
                return a.bookmakerId > b.bookmakerId ? 1 : -1;
            }
        case OrderConstants.NONE:
        default:
            return 0;
    }
};

/**
 * Generate Betfair URL
 *
 * @param alert
 *
 * @return string | null
 */
export const getBetfairChartUrl = (alert: AlertPlayerObject): string | null => {
    if (!alert.bMarketId || !alert.bSelectionId) return null
    return getBetfairChart(alert.bMarketId, alert.bSelectionId)
}


/**
 * get Average Bookmakers
 *
 * @returns {}
 * @param playerIds
 * @param bookmakers
 */
export function getAveragePlayers(
    playerIds: Set<string>,
    bookmakers: PlayerBookmakerStoredObject[]
): { [playerId: string]: PlayerStoredObject } {
    const players: { [playerId: string]: PlayerStoredObject } = {};
    for (const pid of [...playerIds]) {
        const markets: any = Object.values(PLAYER_MARKETS)
            .map((market) => {
                const marketId: string = market.id;
                return {marketId, sign: getAveragePlayerMarket(pid, marketId, bookmakers)};
            })
            .reduce((obj, item) => {
                return {...obj, [item.marketId]: item.sign};
            }, {});
        players[pid] = {id: pid, name: "", markets};
    }

    return players;
}

/**
 * get Average Bookmakers
 *
 * @returns {}
 * @param playerId
 * @param marketId
 * @param bookmakers
 */
export function getAveragePlayerMarket(playerId: string, marketId: string, bookmakers: PlayerBookmakerStoredObject[]): PlayerMarketStoredObject {
    let average: PlayerMarketStoredObject = {}
    let count: any = {}

    for (const bookmaker of bookmakers) {
        const player: any = bookmaker.players[playerId] || {};
        let playerSbvs = player.markets?.[marketId];
        if (!playerSbvs) continue;
        for (const sbv of Object.keys(playerSbvs)) {
            for (const signId of Object.keys(playerSbvs[sbv])) {
                if (!average[sbv]) {
                    average[sbv] = {[signId]: {odd: playerSbvs[sbv][signId].odd, updt: 0}}
                    count[sbv] = {[signId]: 1}
                } else if (!average[sbv][signId]) {
                    average[sbv][signId] = {odd: playerSbvs[sbv][signId].odd, updt: 0}
                    count[sbv][signId] = 1
                } else if (average[sbv][signId]) {
                    average[sbv][signId].odd += playerSbvs[sbv][signId].odd
                    count[sbv][signId]++
                }
            }
        }
    }
    for (const sbv of Object.keys(average)) {
        for (const sign of Object.keys(average[sbv])) {
            average[sbv][sign].odd = Math.round((average[sbv][sign].odd / count[sbv][sign]) * 100) / 100
            average[sbv][sign].updt = new Date().getTime()
        }
    }

    return average;
}

export function getMainSbv(players: PlayerBookmakersStoredObject, marketId: string) {
    if (!marketId) return {}
    let res: { [playerId: string]: { [bookmaker: string]: string } } = {};
    for (const book of Object.keys(players)) {
        for (const player of Object.keys(players[book].players)) {
            if (PLAYER_MARKETS[marketId] && !PLAYER_MARKETS[marketId].sbv) {
                if (!res[player]) {
                    res[player] = {[book]: '#'}
                }
                if (!res[player][book]) {
                    res[player][book] = '#'
                }
                if (!res[player]['0']) res[player]['0'] = '#'
            } else {
                if (players[book]?.players[player]?.markets?.[marketId] && Object.keys(players[book].players[player].markets[marketId]).length > 0) {
                    const markets = players[book].players[player].markets[marketId]
                    let min = Infinity
                    let sbvKey = '#'
                    for (const sbv of Object.keys(markets)) {
                        const signs = markets[sbv];
                        let odds = Object.values(signs).map(sign => sign.odd)
                        if (odds.length === 1) {
                            odds = odds.concat(1)
                        }
                        const maxOdd = Math.max(...odds);
                        const minOdd = Math.min(...odds);
                        const dif = maxOdd - minOdd;
                        if (dif < min) {
                            min = dif;
                            sbvKey = sbv;
                        }
                    }
                    if (!res[player]) {
                        res[player] = {[book]: sbvKey}
                    }
                    if (!res[player][book]) {
                        res[player][book] = sbvKey
                    }
                }
            }
        }
    }
    return res;
}

function getBookmakerSigns(playerId: string, marketId: string, sbv: string, signId: string, bookmakers: ProcessPlayerBookmakerStoredObject[]): {
    [bookmakerId: string]: {
        name: string,
        sign: SignStoredObject
    }
} {
    return bookmakers
        .filter(bookmaker => bookmaker.players?.[playerId]?.markets?.[marketId]?.[sbv]?.[signId])
        .reduce((a, v) => ({
            ...a, [v.bookmakerId]: {
                name: v.players[playerId].name,
                sign: v.players[playerId].markets[marketId][sbv][signId]
            }
        }), {})
}

/**
 * Process Comparison
 *
 * @param eventId
 * @param comparison
 * @param bookmakers
 * @param settings
 * @param hides
 * @param booked
 * @param comparisonId
 */
export function processComparison(
    eventId: number,
    comparison: { [playerId: string]: PlayerStoredObject },
    bookmakers: ProcessPlayerBookmakerStoredObject[],
    settings: PlayerSettingsObject,
    hides: HiddenObject,
    booked: string[],
    comparisonId: number
) {
    return Object.entries(comparison)
        .flatMap(([playerId, player]) => {
            let results: any[] = [];
            Object.entries(player.markets).forEach(([marketId, market]) => {
                Object.entries(market).forEach(([sbv, signs]) => {
                    Object.entries(signs).forEach(([signId, sign]) => {
                        const alert = processPlayerSign(
                            eventId,
                            playerId,
                            sign,
                            getBookmakerSigns(playerId, marketId, sbv, signId, bookmakers),
                            marketId,
                            sbv,
                            signId,
                            settings,
                            comparisonId
                        )
                        if (alert) {
                            results.push(alert)
                        }
                    })
                })
            })

            return results
                .filter((alert: any) => !booked.includes(`${eventId}-${alert.playerId}-${alert.marketId}`))
                .filter((alert: any) => !Object.keys(hides).includes(`${eventId}-${alert.playerId}-${alert.marketId}`) || hides[`${eventId}-${alert.playerId}-${alert.signId}`] > alert.margin)
                .flat();
        });
}

/**
 * Process Bookmakers
 *
 * @param eventId
 * @param playerId
 * @param comparison
 * @param playerBookmakers
 * @param marketId
 * @param sbv
 * @param signId
 * @param settings
 * @param comparisonId
 */
function processPlayerSign(
    eventId: number,
    playerId: string,
    comparison: SignStoredObject,
    playerBookmakers: {
        [bookmakerId: string]: {
            name: string,
            sign: SignStoredObject
        }
    },
    marketId: string,
    sbv: string,
    signId: string,
    settings: PlayerSettingsObject,
    comparisonId: number
): AlertPlayerObject | null {
    const alerts = Object.entries(playerBookmakers)
        .map(([bookmakerId, player]) => processSign(
            eventId,
            playerId,
            player.name,
            bookmakerId,
            marketId,
            sbv,
            signId,
            comparison,
            player.sign,
            settings,
            comparisonId
        ))
        .filter((alert: AlertPlayerObject | null) => alert != null)
        .sort((a: any, b: any) => comparisonId === AVERAGE_2 ? b.margin - a.margin : a.margin - b.margin)
        .filter((_, idx) => idx === 0);
    return alerts.length ? alerts[0] : null
}

/**
 * Process Bookmakers
 *
 * @param eventId
 * @param playerId
 * @param playerName
 * @param bookmakerId
 * @param marketId
 * @param sbv
 * @param signId
 * @param comparisonSign
 * @param playerSign
 * @param settings
 * @param comparisonId
 */
function processSign(
    eventId: number,
    playerId: string,
    playerName: string,
    bookmakerId: string,
    marketId: string,
    sbv: string,
    signId: string,
    comparisonSign: SignStoredObject,
    playerSign: SignStoredObject,
    settings: PlayerSettingsObject,
    comparisonId: number
): AlertPlayerObject | null {
    if (!settings.markets.includes(marketId + '#' + signId)) return null;
    const comparisonOdd = comparisonSign.odd;
    if (comparisonOdd <= 1) return null;
    //Get bookamker odd
    const bookOdd = playerSign.odd;
    if ((bookOdd && bookOdd < settings.from) || (settings.to !== 1 && bookOdd > settings.to)) return null;
    // Calculate Margin
    const comparisonSettings = settings.comparisons[comparisonId.toString()]
    const margin = calculateMargin(comparisonOdd, bookOdd, comparisonSettings.method);
    if (((comparisonId === AVERAGE || comparisonId === BETFAIR) && margin > comparisonSettings.threshold)
        || (comparisonId === AVERAGE_2 && margin < comparisonSettings.threshold)
    ) return null
    // Filter amount market and outcome
    if (comparisonId === BETFAIR) {
        if (comparisonSign.marketTV && comparisonSign.marketTV < comparisonSettings.amountMarket) return null
        if (comparisonSign.runnerTV && comparisonSign.runnerTV < comparisonSettings.amountOutcome) return null
    }
    return {
        eventId: eventId,
        playerId: playerId,
        comparisonType: comparisonId,
        comparisonOdd: Math.round(comparisonOdd * 100) / 100,
        backOdd: bookOdd,
        marketId: marketId,
        sbv: sbv,
        signId: signId,
        name: playerName,
        bookmakerId: parseInt(bookmakerId),
        margin: margin,
        marketTV: comparisonSign.marketTV || 0,
        runnerTV: comparisonSign.runnerTV || 0,
        bMarketId: comparisonSign.bMarketId || undefined,
        bSelectionId: comparisonSign.bSelectionId || undefined,
    };
}

/**
 * Compare Bookmaker
 *
 * @returns {number}
 * @param comparisonOdd
 * @param bookmakerOdd
 * @param method
 */
function calculateMargin(comparisonOdd: number, bookmakerOdd: number, method: null | MarginMethod): number {
    let result = method === MarginMethod.ORIGINAL ?
        (comparisonOdd - bookmakerOdd) / (comparisonOdd / 100) : (100 / bookmakerOdd) - (100 / comparisonOdd);
    return Math.round(result * 10) / 10;
}

/**
 * eventsListener
 *
 * @returns AlertMonitorObject
 * @param event
 * @param settings
 * @param hides
 * @param booked
 */
export function processEvent(event: EventPlayerStoredObjects, settings: PlayerSettingsObject, hides: HiddenObject, booked: string[]): AlertPlayerObject[] {
    let alerts: any = [];
    try {
        if (!("bookmakers" in event) || !settings || !isVisible(eventToStore(event), settings.sports)) {
            return [];
        }

        const expire = new Date();
        if (settings.date < 0) {
            expire.setHours(expire.getHours() - settings.date);
        } else {
            expire.setHours(0);
            expire.setMinutes(0);
            expire.setSeconds(0);
            expire.setDate(expire.getDate() + settings.date);
        }

        const startdt = new Date(event.date);
        if (startdt < new Date() || (settings.date !== 0 && startdt >= expire)) {
            return [];
        }

        const bookmakers: any = event.bookmakers;
        const bookmakerToCompare: any = Object.keys(event.bookmakers)
            .filter(
                (bookmakerId) =>
                    settings.bookmakers.includes(parseInt(bookmakerId)) && !COMPARISONS.includes(parseInt(bookmakerId))
            )
            .map((bookmakerId) => Object.assign({}, bookmakers[bookmakerId], {bookmakerId: parseInt(bookmakerId)}));

        //Start BETFAIR calculation
        if (settings.comparisons[BETFAIR.toString()].status && Object.keys(bookmakers).includes(BETFAIR.toString())) {
            alerts = alerts.concat(
                processComparison(event.eventId, bookmakers[BETFAIR.toString()].players, bookmakerToCompare, settings, hides, booked, BETFAIR)
            )
        }

        // Start AVERAGE calculation
        const averageBookmakers = settings.comparisons[AVERAGE.toString()]?.bookmakers || []
        const activeAverageBookmakers: PlayerBookmakerStoredObject[] = Object.keys(event.bookmakers)
            .filter((bookmakerId) => !COMPARISONS.includes(parseInt(bookmakerId)) && averageBookmakers.includes(parseInt(bookmakerId)))
            .map((bookmakerId) => event.bookmakers[bookmakerId]);
        if (settings.comparisons[AVERAGE.toString()].status && activeAverageBookmakers.length > 0) {
            const playerIds = new Set(Object.values(event.bookmakers).flatMap((book) => Object.keys(book.players)));
            const bComparison = getAveragePlayers(playerIds, activeAverageBookmakers);
            alerts = alerts.concat(processComparison(event.eventId, bComparison, bookmakerToCompare, settings, hides, booked, AVERAGE));
        }

        //Start AVERAGE_2 calculation
        const average2Bookmakers = settings.comparisons[AVERAGE_2.toString()]?.bookmakers || []
        const activeAverage2Bookmakers: PlayerBookmakerStoredObject[] = Object.keys(event.bookmakers)
            .filter((bookmakerId) => !COMPARISONS.includes(parseInt(bookmakerId)) && average2Bookmakers.includes(parseInt(bookmakerId)))
            .map((bookmakerId) => event.bookmakers[bookmakerId]);
        if (settings.comparisons[AVERAGE_2.toString()].status && activeAverage2Bookmakers.length > 0) {
            const playerIds = new Set(Object.values(event.bookmakers).flatMap((book) => Object.keys(book.players)));
            const bComparison = getAveragePlayers(playerIds, activeAverage2Bookmakers);
            alerts = alerts.concat(processComparison(event.eventId, bComparison, bookmakerToCompare, settings, hides, booked, AVERAGE_2));
        }

    } catch (error) {
        console.error("Error on process event", error, event);
    }
    return alerts;
}

/**
 * Get Event fields
 *
 * @returns {object}
 * @param event
 */
export function eventToStore(event: EventPlayerStoredObjects): EventStoredObject {
    return {
        _id: event.eventId,
        sportId: event.sportId,
        sport: event.sport,
        categoryId: event.categoryId,
        category: event.category,
        tournamentId: event.tournamentId,
        tournament: event.tournament,
        name: event.name,
        date: event.date
    };
}

export function getPlayers(result: PlayerBookmakersStoredObject, averageBookmakers: number[]): {
    [id: string]: { id: string, name: string, team: string, maps: number, averages: any }
} {

    if (!Object.keys(result).length) return {}

    const activeBookmakers: PlayerBookmakerStoredObject[] = Object.entries(result)
        .filter(([bookmakerId, _]) => averageBookmakers.includes(parseInt(bookmakerId)))
        .map(([_, bookmaker]) => bookmaker)

    const playerIds: any = new Set(Object.values(result).flatMap((book: any) => Object.keys(book.players)));
    const averages = getAveragePlayers(playerIds, activeBookmakers);

    let players: { [id: string]: { id: string, name: string, team: string, maps: number, averages: any } } = {}
    Object.values(result).forEach((bookmaker: any) => {
        Object.entries(bookmaker.players).forEach(([p_id, p]: [p_id: string, p: any]) => {
            if (!(p_id in players)) {
                players[p_id] = {
                    id: p_id,
                    name: p.fullName || p.name,
                    team: p.team,
                    maps: 1,
                    averages: averages[p_id]['markets']
                }
            } else {
                if (!players[p_id]['name'] && p.fullName)
                    players[p_id]['name'] = p.fullName
                else if (!players[p_id]['name'] && p.name)
                    players[p_id]['name'] = p.name
                if (!players[p_id]['team'] && p.team)
                    players[p_id]['team'] = p.team
                players[p_id]['maps']++
            }
        })
    })
    return players
}

export function keySearch(query: string, alert: AlertPlayerObject, event: EventStoredObject) {
    return query === '' || alert.name.toLowerCase().includes(query)
        || event.name.toLowerCase().includes(query)
        || event.category.toLowerCase().includes(query)
        || event.tournament.toLowerCase().includes(query)
}