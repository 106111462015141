import {call, put} from 'redux-saga/effects';
import {ISkeletonSaga, skeletonSaga} from '../../apis/skeleton/skeletonSaga';
import {BookPlayerPayload} from '../../@types/actions';
import {BOOK_EVENT_ERROR, BOOK_EVENT_SUCCESS} from "../../constants/PlayerConstants";
import {BookPlayerCall} from '../../apis/user';

function* tryFunction(payload: BookPlayerPayload) {
    yield call(BookPlayerCall, payload);
    yield put({
        type: BOOK_EVENT_SUCCESS,
        payload: `${payload.eventId}-${payload.playerId}-${payload.marketId}`
    });
}

export function* bookEventSaga({payload}: { payload: BookPlayerPayload }): Generator {
    const sagaPayload: ISkeletonSaga = {
        tryApiCall: {function: tryFunction, payload: payload},
        failedType: BOOK_EVENT_ERROR
    };
    yield call(skeletonSaga, sagaPayload);
}
