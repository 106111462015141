import React, {useContext, useEffect} from "react";
import {InstantDropObject} from "../../../@types/formContext";
import {getMarketName} from "../../../constants/CommonConstants";
import {BookmakerData} from "../../../@types/response";
import {useSelector} from "react-redux";
import {getBookmakers} from "../../../store/selectors/getBookmakers";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faClock,
    faLongArrowAltDown,
    faLongArrowAltUp,
    faMagnifyingGlass,
    faTrash,
} from "@fortawesome/free-solid-svg-icons";
import {EventModalProps} from "../../../@types/inputs";
import {MODALS_TYPES} from "../../../constants/ModalsConstants";
import {ModalsContext} from "../modals/ModalsContext";
import {getUid} from "../../../store/utils/droppingOddsUtils";

const skewContainer: any = {
    backgroundColor: "#37474f",
    transform: "skew(-42deg)",
    marginRight: "10px",
    height: "24px",
    background: "#761311",
    width: "70px",
};
const skewText: any = {
    color: "white",
    transform: "skew(42deg)",
    textAlign: "center",
    paddingTop: "3px",
};

interface DoInstantItemProps {
    item: InstantDropObject;
    beep?: boolean;
    handleSingleDelete: (uid: string) => void;
}

const beepPlayer = new Audio("/beep.mp3");
const DoInstantItem: React.FC<DoInstantItemProps> = ({item, beep, handleSingleDelete}) => {
    const bookmakers: BookmakerData = useSelector(getBookmakers);
    const nSigns = Object.keys(item.n).length;

    useEffect(() => {
        if (beep) {
            beepPlayer.play();
        }
    }, []);

    const {setOpenedModalsCallback, setModalsDataCallback} = useContext(ModalsContext);

    const drop_time = new Date(item.tm * 1000).toLocaleTimeString("it-IT");

    return (
            <div className="data-row ng-tns-c238-18 ng-trigger ng-trigger-newRowAnimation ng-star-inserted text-white text-xs ">
                <div className="title px-4"></div>
                <div className="odds-content ng-tns-c238-18 bg-[#0f1417c4] rounded-[20px]  border border-t-[#484A4C] border-l-[#484A4C] border-r-[#5C6164] border-b-[#5C6164]  pl-2 pt-2 pr-2 mb-2">
                    <div className=" flex justify-between ">
                        <div className="relative">
                            <p className="text-xs text-white w-52 overflow-hidden">{item.e} </p>
                            <div className="text-[#1F507E] text-xs">
                                <span>{item.c}</span>&nbsp; <span className="px-0-1 "> &gt; </span> &nbsp;
                                <span>{item.t}</span>
                            </div>

                            <span className="spacer ng-tns-c238-18 "/>
                            <div className="bg-[#292F3A] h-px w-52"></div>
                        </div>

                        <div>
                        <span className="time ng-tns-c238-18 text-yellow-400 ml-4">
                            <FontAwesomeIcon className="text-gray-500" icon={faClock}/>
                            <span className="ng-tns-c238-18 text-white"> {drop_time}</span>
                        </span>
                            <div className="flex">
                                <div className="cursor-pointer" onClick={() => handleSingleDelete(getUid(item))}>
                                    <FontAwesomeIcon icon={faTrash}/>
                                </div>
                                <div style={skewContainer}>
                                    <p style={skewText}>
                                        {item.v > 0 ? "+" : ""} {item.v.toFixed(1)}%
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex justify-around mt-1">
                        <p className="col-6 w-[49%]  text-center bg-[#1A3854]">
                            {" "}
                            <span
                                    className="mr-2 -ml-2 cursor-pointer"
                                    onClick={() => {
                                        const _event: EventModalProps = {id: item.id, name: item.e, sportId: item.sId};
                                        setModalsDataCallback(MODALS_TYPES.EVENT_DETAILS_MODAL, {event: _event});
                                        setOpenedModalsCallback(MODALS_TYPES.EVENT_DETAILS_MODAL, true);
                                    }}
                            >
                            <FontAwesomeIcon icon={faMagnifyingGlass}/>
                        </span>
                            {bookmakers[item.bId]?.name || "Not Found"}
                        </p>
                        <p className="col-6 w-[49%] text-center bg-[#45494C]">{getMarketName(item.mId)}</p>
                    </div>
                    <div className="flex  border-monitor-gray-300 pt-2 pb-[0.1rem]">
                        <div className="flex-column w-full justify-between text-white">
                            <div className="flex justify-around text-center">
                                {Object.keys(item.p).map((previousSign, idx) => {
                                    return <div key={idx} className={`w-2/${nSigns} px-1`}>
                                        <span className="value ng-tns-c238-18 underlined ng-star-inserted pl-4 text-gray-500">
                                            {item.p[previousSign].toFixed(2)}
                                        </span>
                                    </div>
                                })}
                            </div>
                            <div className="flex justify-around	text-center mt-1 mb-1">
                                {Object.keys(item.n).map((currentSign, idx) => {
                                    return <div
                                            key={idx}
                                            className={`w-2/${nSigns} px-1 flex-column cursor-pointer`}
                                            onClick={() => {
                                                setModalsDataCallback(MODALS_TYPES.SIGN_CHART_MODAL, {
                                                    odd: {
                                                        eventId: item.id,
                                                        bookmakerId: item.bId,
                                                        marketId: item.mId,
                                                        signId: (idx + 1).toString(),
                                                    },
                                                    event: {
                                                        id: item.id,
                                                        name: item.e
                                                    },
                                                });
                                                setOpenedModalsCallback(MODALS_TYPES.SIGN_CHART_MODAL, true);
                                            }}
                                    >
                                        <span className="odds-arrow ng-tns-c238-18 ng-star-inserted  px-1">
                                            {item.n[currentSign] > item.p[currentSign] && (
                                                    <FontAwesomeIcon
                                                            className="text-green-800"
                                                            icon={faLongArrowAltUp}/>
                                            )}
                                            {item.n[currentSign] < item.p[currentSign] && (
                                                    <FontAwesomeIcon
                                                            className="text-red-800"
                                                            icon={faLongArrowAltDown}/>
                                            )}
                                        </span>
                                        <span className={`value ng-tns-c238-18 ng-star-inserted ${item.vId === idx ? "underline" : ""}`}>
                                            {item.n[currentSign].toFixed(2)}
                                        </span>
                                    </div>
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    );
};

export default React.memo(DoInstantItem);
