import {INSTANT_DROPPING_LIST_SIZE, INSTANT_DROPPING_SHOW_SIZE, OrderConstants} from "../../constants/OddsConstants";
import {DoClassicSettingsObject, DroppingOddObject, MarketCapObject, MoneyLineObject} from "../../@types/response";
import {DropListFilters, InstantDropObject} from "../../@types/formContext";
import {BETFAIR, MarginMethod, PINNACLE} from "../../constants/CommonConstants";
import {InstantDropLists} from "../../@types/inputs";


export const processDroppingOdd = (item: DroppingOddObject, settings: DoClassicSettingsObject, bookmaker: number) => {
    if (settings.comparisons[bookmaker.toString()].method === MarginMethod.ORIGINAL) {
        item['margin-0'] = (item['opening'] - item['odd']) / (item['opening'] / 100);
        item['margin-15'] = (item['odd-15'] - item['odd']) / (item['odd-15'] / 100);
        item['margin-30'] = (item['odd-30'] - item['odd']) / (item['odd-30'] / 100);
        item['margin-60'] = (item['odd-60'] - item['odd']) / (item['odd-60'] / 100);
        item['margin-120'] = (item['odd-120'] - item['odd']) / (item['odd-120'] / 100);
        item['margin-180'] = (item['odd-180'] - item['odd']) / (item['odd-180'] / 100);
        item['margin-240'] = (item['odd-240'] - item['odd']) / (item['odd-240'] / 100);
        item['margin-300'] = (item['odd-300'] - item['odd']) / (item['odd-300'] / 100);
    } else {
        item['margin-0'] = (100 / item['odd']) - (100 / item['opening']);
        item['margin-15'] = (100 / item['odd']) - (100 / item['odd-15']);
        item['margin-30'] = (100 / item['odd']) - (100 / item['odd-30']);
        item['margin-60'] = (100 / item['odd']) - (100 / item['odd-60']);
        item['margin-120'] = (100 / item['odd']) - (100 / item['odd-120']);
        item['margin-180'] = (100 / item['odd']) - (100 / item['odd-180']);
        item['margin-240'] = (100 / item['odd']) - (100 / item['odd-240']);
        item['margin-300'] = (100 / item['odd']) - (100 / item['odd-300']);
    }
    return item;
}

export const filterDroppingOdds = (
    droppingOdds: DroppingOddObject[],
    settings: DoClassicSettingsObject,
    bookmaker: number,
    marketCaps: MarketCapObject,
    marketMoneyLines: MoneyLineObject,
): DroppingOddObject[] => {
    if (!droppingOdds) return []
    const expire = new Date()
    expire.setHours(0)
    expire.setMinutes(0)
    expire.setSeconds(0)
    expire.setDate(expire.getDate() + settings.date)
    const data = droppingOdds
        .filter((d: any) => {
            const startdt = new Date(d.date)
            return (d[`margin-${settings.reference}`] && !isNaN(d[`margin-${settings.reference}`]) && d[`margin-${settings.reference}`] > 0) &&
                (
                    (bookmaker === BETFAIR && (!marketCaps[`${d.id}-${d.marketId}-${d.signId}`] || (marketCaps[`${d.id}-${d.marketId}-${d.signId}`].marketTV >= settings.comparisons[BETFAIR].amountMarket && marketCaps[`${d.id}-${d.marketId}-${d.signId}`].runnerTV >= settings.comparisons[BETFAIR].amountOutcome)))
                    || (bookmaker === PINNACLE && (!marketMoneyLines[d.id] || ((d.marketId === 2 && marketMoneyLines[d.id].maxTotal >= settings.comparisons[PINNACLE].amountMarket) || (d.marketId !== 2 && marketMoneyLines[d.id].maxMoneyline >= settings.comparisons[PINNACLE].amountMarket))))
                ) && (
                    (startdt > new Date()) && (settings.date === 0 || startdt <= expire)
                ) && (
                    ((settings.from !== 1 && settings.to === 1) && d.odd >= settings.from)
                    || ((settings.to !== 1 && settings.from === 1) && d.odd <= settings.to)
                    || ((settings.to !== 1 && settings.from !== 1) && d.odd >= settings.from && d.odd <= settings.to)
                    || (settings.from === 1 && settings.to === 1)
                )
        })
        .sort((a: DroppingOddObject, b: DroppingOddObject) => {
            switch (settings.order) {
                case OrderConstants.DATE:
                    return a.date - b.date;
                case OrderConstants.MARGIN:
                    // @ts-ignore
                    return b["margin-" + settings.reference] - a["margin-" + settings.reference];
                case OrderConstants.NONE:
                default:
                    // @ts-ignore
                    return b["margin-" + settings.reference] - a["margin-" + settings.reference];
            }
        })
        .filter((_d, i) => i < settings.pageSize);
    console.clear();

    return data;
};

export const updateInstantDropLists = (item: InstantDropObject, instants: InstantDropLists): InstantDropLists => {
    if (!instants[item.bId.toString()]) instants[item.bId.toString()] = [];
    instants[item.bId.toString()] = [item, ...instants[item.bId.toString()]].slice(0, INSTANT_DROPPING_LIST_SIZE)
    return instants;
}

export const filterInstantDroppingOdds = (
    droppings: InstantDropLists,
    from: number,
    to: number,
    date: number,
    search: string,
    filter: DropListFilters
): InstantDropObject[] => {
    let result: InstantDropObject[] = []
    Object.keys(droppings).forEach(k => {
        if (filter.bookmakers.has(parseInt(k))) {
            result = result.concat(droppings[k])
        }
    });
    return result
        .filter((i: InstantDropObject) => isItemValid(i, from, to, date, search, filter))
        .slice(0, INSTANT_DROPPING_SHOW_SIZE);
};

export const getUid = (item: InstantDropObject) => {
    return `${item.bId}#${item.id}#${item.tm}`
}


export const isItemValid = (
    item: InstantDropObject,
    from: number,
    to: number,
    date: number,
    search: string,
    filter: DropListFilters
): boolean => {
    const expire = new Date()
    expire.setHours(23)
    expire.setMinutes(59)
    expire.setSeconds(59)
    expire.setDate(expire.getDate() + date - 1)
    const startdt = new Date(item.d)
    return (filter.from / 1000) < item.tm
        && filter.bookmakers.has(item.bId)
        && (!!(item.sId && item.cId && item.tId) &&
            (!!((filter.sport[item.sId.toString()] ?? false) === true ||
                //@ts-ignore
                (typeof filter.sport[item.sId.toString()] === 'object' && ((filter.sport[item.sId.toString()][item.cId.toString()] ?? false) === true)) ||
                //@ts-ignore
                (typeof filter.sport[item.sId.toString()] === 'object' && (filter.sport[item.sId.toString()][item.cId.toString()] ?? []).includes(item.tId))))
        )
        && filter.threshold < -item.v
        && !filter.hide.includes(item.uid || '')
        && (
            (item.e !== null && item.e.toLowerCase().includes(search.toLowerCase())) ||
            (item.c !== null && item.c.toLowerCase().includes(search.toLowerCase())) ||
            (item.t !== null && item.t.toLowerCase().includes(search.toLowerCase()))
        ) && (
            ((from !== 1 && to === 1) && Object.values(item.n)[item.vId] >= from)
            || ((to !== 1 && from === 1) && Object.values(item.n)[item.vId] <= to)
            || ((to !== 1 && from !== 1) && Object.values(item.n)[item.vId] >= from && Object.values(item.n)[item.vId] <= to)
            || (from === 1 && to === 1)
        ) && (
            (startdt > new Date()) && (date === 0 || startdt <= expire)
        )

};
