import React, {Fragment, useCallback, useContext, useMemo, useState} from "react";
import {RoutesEnum, routesPaths} from "../../routes/routes";
import {useLocation, useNavigate} from "react-router-dom";
import {HistoryContext} from "../components/common/History/HistoryContext";
import {useDispatch, useSelector} from "react-redux";
import {LogoutAction} from "../../store/actions/LogoutAction";
import isLoggedIn from "../../store/selectors/isLoggedIn";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faArrowRight,
    faBullseye,
    faC,
    faChartLine,
    faDesktop,
    faFire,
    faI,
    faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import getClient from "../../store/selectors/getClient";

const LINKS_MAPPING: {
    title: string;
    link: string;
    id: string;
    order: number;
    permission: string;
    icon: IconProp;
}[] = [
    {
        title: "MONITOR",
        link: routesPaths[RoutesEnum.MONITOR],
        id: "main_monitor",
        order: RoutesEnum.MONITOR,
        permission: 'monitor',
        icon: faDesktop,
    },
    {
        title: "DROPPING",
        link: routesPaths[RoutesEnum.CLASSIC_VIEW],
        id: "main_" + RoutesEnum.DROPPING_ODDS,
        order: RoutesEnum.DROPPING_ODDS,
        permission: 'dropping-odds',
        icon: faChartLine,
    },
    {
        title: "PLAYERS",
        link: routesPaths[RoutesEnum.PLAYERS],
        id: "main_" + RoutesEnum.PLAYERS,
        order: RoutesEnum.PLAYERS,
        permission: 'players',
        icon: faBullseye,
    },
    {
        title: "HOT MATCHES",
        link: routesPaths[RoutesEnum.HOT_MATCHES],
        id: "main_" + RoutesEnum.HOT_MATCHES,
        order: RoutesEnum.HOT_MATCHES,
        permission: 'hot-matches',
        icon: faFire,
    },
];

const LinkItem: React.FC<{
    currentRoute: string;
    title: string;
    link: string;
    onClick: any;
    id: string;
    icon: IconProp;
    expand: boolean;
}> = ({currentRoute, link, onClick, title, id, icon, expand}) => {
    const location = useLocation();
    const isCurrent = useMemo(
            () => LINKS_MAPPING.find((item) => item.link === location.pathname)?.id === id,
            [location, id]
    );

    return (
            <li
                    className={
                        "inline-block no-underline 2xl:text-lg text-sm border-t border-menu-borderColor w-full text-menu-otherPages text-right hover:text-menu-currentPage hover:text-underline"
                    }
            >
                <a href={link} onClick={(e) => e.preventDefault()}>
                    <div
                            className={
                                    "relative cursor-pointer py-2 pr-4 " +
                                    (isCurrent ||
                                    (title === "DROPPING" &&
                                            (location.pathname.endsWith(routesPaths[RoutesEnum.CLASSIC_VIEW]) ||
                                                    location.pathname.endsWith(routesPaths[RoutesEnum.INSTANT_VIEW])))
                                            ? "nav__item--current text-menu-currentPage"
                                            : "")
                            }
                            onClick={() => {
                                currentRoute !== link && onClick(link, id);
                            }}
                    >
                        {(isCurrent ||
                                (title === "DROPPING" &&
                                        (location.pathname.endsWith(routesPaths[RoutesEnum.CLASSIC_VIEW]) ||
                                                location.pathname.endsWith(routesPaths[RoutesEnum.INSTANT_VIEW])))) && (
                                <div className="border-2 border-menu-currentPage left absolute top-0 h-full"/>
                        )}
                        <div className={expand ? "flex justify-between items-center" : "hidden"}>
                            <FontAwesomeIcon className="ml-3" icon={icon}/>
                            <span className="nav__item-title ml-2">{title}</span>
                        </div>
                        <div className="show-page-name">
                            <FontAwesomeIcon className={expand ? "hidden" : "inline-block"} icon={icon}/>
                            <span>{title}</span>
                        </div>
                    </div>
                </a>
            </li>
    );
};


const SubLinkItem: React.FC<{
    id: string;
    link: string;
    onClick: any;
    title: string;
    currentRoute: string;
    icon: IconProp;
    expand: boolean;
}> = ({id, link, onClick, title, currentRoute, icon, expand}) => {

    const location = useLocation();
    const isCurrent = useMemo(
            () => location.pathname.endsWith(link),
            [location, id, link]
    );

    return <li
            id={id}
            className={
                    "inline-block no-underline 2xl:text-lg text-sm border-t border-menu-borderColor w-full text-menu-otherPages text-right " +
                    "hover:text-menu-currentPage hover:text-underline nav__item--current text-menu-currentPage"
            }
    >
        <a href={link} onClick={(e) => e.preventDefault()}>
            <div
                    className={"relative cursor-pointer py-2 pr-4  "}
                    onClick={() => currentRoute !== link && onClick(link, id)}
            >
                {isCurrent && <div className="border-2 border-menu-currentPage left absolute top-0 h-full"/>}
                <div
                        className={expand ? "flex justify-between items-center" : "hidden"}
                        style={{
                            color: isCurrent ? "#0091FF" : "#6E7978",
                        }}
                >
                    <FontAwesomeIcon className="ml-3" icon={icon}/>
                    <span className="nav__item-title ml-2 uppercase text-lg">{title}</span>
                </div>
                <div
                        className="show-page-name text-end"
                        style={{
                            color: isCurrent ? "#0091FF" : "#6E7978",
                        }}
                >
                    <FontAwesomeIcon
                            className={expand ? "hidden" : "inline-block"}
                            icon={icon}
                    />
                    <span className="uppercase text-lg">{title}</span>
                </div>
            </div>
        </a>
    </li>
};


const LeftMenu: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const {currentRoute} = useContext(HistoryContext);
    const loggedIn: boolean = useSelector(isLoggedIn);
    const client: any = useSelector(getClient);
    const [expand, setExpand] = useState(false);
    const width = expand ? "2xl:w-52 w-40" : "w-12";
    const position = expand ? "2xl:left-56 left-44" : "left-16";

    const dispatch = useDispatch();

    const cbOnClick = useCallback(
            (link: string) => {
                navigate(link);
            },
            [navigate]
    );

    return (
            <>
                <div
                        className="relative top-40 px-3 lg:items-right hidden z-30 lg:block pt-6 lg:pt-0 h-40"
                        id="nav-content"
                >
                    <div
                            className={
                                    "expand-menu border p-1 text-menu-transparent hover:text-white text-xs border-menu-transparent hover:border-menu-expandBar flex items-center transition cursor-pointer " +
                                    position
                            }
                            onClick={() => setExpand(!expand)}
                    >
                        <FontAwesomeIcon
                                icon={faArrowRight}
                                style={expand ? {transform: "rotate(180deg)"} : undefined}
                                className="cursor-pointer transition duration-300"
                        />
                    </div>
                    <ul className={"list-reset menu-list flex flex-col justify-items-end flex-1 align-right " + width}>
                        {loggedIn && <Fragment>
                            {LINKS_MAPPING
                                    .filter(item => client.products.includes(item.permission))
                                    .map((item) => {
                                        return (
                                                <LinkItem
                                                        key={item.id}
                                                        title={item.title}
                                                        currentRoute={currentRoute}
                                                        link={item.link}
                                                        onClick={cbOnClick}
                                                        id={item.id}
                                                        icon={item.icon}
                                                        expand={expand}
                                                />
                                        );
                                    })}
                            <li className="inline-block no-underline 2xl:text-lg text-sm border-t border-menu-borderColor w-full text-right text-menu-otherPages hover:text-menu-currentPage hover:text-underline">
                                <div className="relative cursor-pointer py-2 pr-4 ">
                                    <div className={expand ? "flex justify-between items-center" : "hidden"}>
                                        <FontAwesomeIcon className="ml-3" icon={faSignOutAlt}/>
                                        <span
                                                className="ml-2"
                                                onClick={() => {
                                                    dispatch(LogoutAction());
                                                }}
                                        >
                                        LOGOUT
                                    </span>
                                    </div>
                                    <div className="show-page-name">
                                        <FontAwesomeIcon
                                                onClick={() => {
                                                    dispatch(LogoutAction());
                                                }}
                                                className={expand ? "hidden" : "inline-block"}
                                                icon={faSignOutAlt}
                                        />
                                        <span>LOGOUT</span>
                                    </div>
                                </div>
                            </li>
                        </Fragment>
                        }
                    </ul>
                    {(location.pathname.endsWith(routesPaths[RoutesEnum.CLASSIC_VIEW]) || location.pathname.endsWith(routesPaths[RoutesEnum.INSTANT_VIEW])) &&
                            <div className=" relative top-40">
                                <ul
                                        id="new"
                                        className={
                                                "list-reset menu-list flex flex-col justify-items-end flex-1 align-right " + width
                                        }
                                >
                                    <SubLinkItem
                                            id='classic-view'
                                            title='Classic View'
                                            onClick={cbOnClick}
                                            currentRoute={currentRoute}
                                            link={routesPaths[RoutesEnum.CLASSIC_VIEW]}
                                            icon={faC}
                                            expand={expand}
                                    />
                                    <SubLinkItem
                                            id='instant-view'
                                            title='Instant View'
                                            onClick={cbOnClick}
                                            currentRoute={currentRoute}
                                            link={routesPaths[RoutesEnum.INSTANT_VIEW]}
                                            icon={faI}
                                            expand={expand}
                                    />
                                </ul>
                            </div>
                    }
                </div>
            </>
    )
};

export default LeftMenu;
