const Logo: React.FC = () => {
    return (
            <div
                    style={{
                        backgroundImage: "url('/img/logo.svg')",
                    }}
                    className='flex items-end mx-2 pb-2 w-[200px] bg-no-repeat bg-center'></div>
    )
}

export default Logo