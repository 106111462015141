import React from 'react'

const HotMatch: React.FC = () => {
    return (
            <div className='text-center'>
                <p className='text-white'>Page is migrate to Monitor</p>
            </div>
    )
}

export default HotMatch;
